export const SettingsModal = () => {
  return (
    <div>
      <div>settings</div>
      <div>vehicle select</div>
      <div>map management</div>
      <div>overboost mode</div>
      <div>coolant overheat mode</div>
      <div>iat reduction</div>
      <div>can status</div>
    </div>
  );
};
